'use client';

import { useEffect, useState } from 'react';
import {
  createAuthenticationAdapter,
  AuthenticationStatus,
} from '@rainbow-me/rainbowkit';
import { SiweMessage } from 'siwe';

import { UserSession } from '#/types/user';

import { useUserContext } from '../UserContext';

export default function useAuth() {
  const [authStatus, setAuthStatus] = useState<AuthenticationStatus>('loading');
  const { setUserSession } = useUserContext();

  const getNonce = async () => {
    const response = await fetch('/api/nonce');
    return await response.text();
  };

  const createMessage = ({
    nonce,
    address,
    chainId,
  }: {
    nonce: string;
    address: string;
    chainId: number;
  }) => {
    return new SiweMessage({
      domain: window.location.host,
      address,
      statement: 'Sign in with Ethereum to the app.',
      uri: window.location.origin,
      version: '1',
      chainId,
      nonce,
    });
  };

  const getMessageBody = ({ message }: { message: SiweMessage }) => {
    return message.prepareMessage();
  };

  const verify = async ({
    message,
    signature,
  }: {
    message: SiweMessage;
    signature: string;
  }) => {
    try {
      const response = await fetch('/api/verify', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message, signature }),
      });

      const authenticated = Boolean(response.ok);

      if (authenticated) {
        setAuthStatus('authenticated');
      }

      const json = await response.json();
      const user: { id: string; address: string } = json?.user;
      if (user?.address != null) {
        setUserSession((data: UserSession | {} = {}) => ({ ...data, ...user }));
      }

      return authenticated;
    } catch (error) {
      setAuthStatus('unauthenticated');
      return false;
    }
  };

  const signOut = async () => {
    await fetch('/api/logout');
    setAuthStatus('unauthenticated');
  };

  const authAdapter = createAuthenticationAdapter({
    getNonce,
    createMessage,
    getMessageBody,
    verify,
    signOut,
  });

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetch('/api/me');
        const json = await response.json();
        const user = json?.user ?? {};

        if (user?.address) {
          setAuthStatus('authenticated');
          setUserSession((data: UserSession | {} = {}) => ({
            ...data,
            ...user,
          }));
        } else {
          setAuthStatus('unauthenticated');
        }
      } catch (_error) {
        setAuthStatus('unauthenticated');
      }
    };

    const skipLoginUseAddress: string = process.env.NEXT_PUBLIC_SKIP_LOGIN_JUST_USE_ADDRESS || '';

    // Log directly into an address if specified in .env.development
    if (skipLoginUseAddress.length !== 0) {
      setAuthStatus('authenticated');
      setUserSession((data: UserSession | {} = {}) => ({
        ...data,
        address: skipLoginUseAddress,
      }));
    } else {
      fetchStatus();
    }
  }, []);

  return { authStatus, authAdapter };
}
